import { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import { Divider, Typography } from '@mui/material';
import { Entry, EntryCollection } from 'contentful';
import Banner from '../models/Banner';
import contentful from '../services/Contentful';
import BannerHero from '../components/BannerHero';
import Concert from '../models/Concert';
import { formatISO } from 'date-fns';
import Head from 'next/head';
import config from '../config';
import React from 'react';
import ConcertCard from '../components/ConcertCard';

const Index: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({ banner, concerts }) => {
  return (
    <>
      <Head>
        <title>Inicio | {config.site.title}</title>
      </Head>

      {banner && <BannerHero photos={banner.fields.photos} />}

      <Typography variant="subtitle1" sx={{ my: { xs: 2, md: 4 }, fontSize: 25, fontWeight: 500 }}>
        Próximos conciertos
      </Typography>

      {concerts.items.map((concert, index) => (
        <React.Fragment key={index}>
          <ConcertCard concert={concert} />

          {index < concerts.items.length - 1 && <Divider sx={{ my: { xs: 2, md: 4 } }} />}
        </React.Fragment>
      ))}
    </>
  );
};

export const getStaticProps: GetStaticProps<{
  banner?: Entry<Banner>;
  concerts: EntryCollection<Concert>;
}> = async () => {
  const banners = await contentful.getEntries<Banner>({
    content_type: 'banner',
    limit: 1,
    'fields.name': 'Home',
  });

  const concerts = await contentful.getEntries<Concert>({
    content_type: 'concert',
    limit: 3,
    'fields.date[gte]': formatISO(new Date()),
  });

  return {
    props: {
      banner: banners.items?.[0] ?? null,
      concerts,
    },
    revalidate: 300,
  };
};

export default Index;
