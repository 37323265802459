import { Box, Button, Grid, Typography } from '@mui/material';
import { Entry } from 'contentful';
import { format, parseISO } from 'date-fns';
import Link from 'next/link';
import Image from 'next/image';
import loader from '../loader';
import Concert from '../models/Concert';
import { es } from 'date-fns/locale';
import ReactMarkdown from 'react-markdown';

const ConcertCard: React.FC<{ concert: Entry<Concert> }> = ({ concert }) => {
  return (
    <Grid container spacing={{ xs: 2, md: 4 }}>
      <Grid item>
        <Image
          src={`https:${concert.fields.cover.fields.file.url}`}
          alt={concert.fields.cover.fields.title}
          width={400}
          height={200}
          objectFit="cover"
          loader={loader}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle1" sx={{ fontSize: { xs: 18, md: 25 }, fontWeight: 500 }}>
          {concert.fields.name}
        </Typography>
        <Typography color="textSecondary" sx={{ fontSize: 16, mb: 1 }}>
          {format(parseISO(concert.fields.date), "EEEE d 'de' MMMM, yyyy", { locale: es })}
        </Typography>
        <Typography component="div">
          <ReactMarkdown>{concert.fields.description}</ReactMarkdown>
        </Typography>

        <Box sx={{ textAlign: 'right', mt: 1 }}>
          <Link href={`/conciertos/${concert.fields.slug}`} passHref>
            <Button variant="contained" color="primary">
              Más información
            </Button>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConcertCard;
