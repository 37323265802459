import { Box, ButtonBase, Typography } from '@mui/material';
import { Asset } from 'contentful';
import Image from 'next/image';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import loader from '../loader';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface BannerHeroProps {
  photos: Asset[];
}

const BannerHero: React.FC<BannerHeroProps> = ({ photos }) => {
  const [current, setCurrent] = useState(0);

  return (
    <Box sx={{ backgroundColor: 'black', position: 'relative' }}>
      <AutoPlaySwipeableViews index={current} onChangeIndex={(value) => setCurrent(value)} interval={5000}>
        {photos.map((photo, index) => (
          <Box key={index} sx={{ position: 'relative' }}>
            <Image
              src={`https:${photo.fields.file.url}`}
              alt={photo.fields.title}
              width="1024"
              height="400"
              layout="responsive"
              loader={loader}
              objectFit="cover"
              priority
            />

            {/* <Box
              sx={{
                display: { xs: 'none', md: 'block' },
                backgroundColor: 'primary.main',
                padding: 3,
                position: 'absolute',
                bottom: 0,
                right: 0,
                maxWidth: 400,
                pb: 6,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontSize: 20, mb: 2 }}>
                Das ist die Spielzeit 2022/23
              </Typography>

              <Typography>
                Entdecken Sie hier das Konzertprogramm, die Abonnements und Themen-Schwerpunkte der kommenden Saison!
              </Typography>
            </Box> */}
          </Box>
        ))}
      </AutoPlaySwipeableViews>

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'absolute', bottom: 10 }}>
        {photos.map((_, index) => (
          <ButtonBase
            key={index}
            onClick={() => setCurrent(index)}
            sx={{
              width: 10,
              height: 10,
              backgroundColor: 'white',
              borderRadius: '50%',
              margin: '0 4px',
              opacity: 0.5,
              ...(index == current && {
                opacity: 1.0,
              }),
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BannerHero;
